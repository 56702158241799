import * as React from 'react';
import { Col } from 'react-bootstrap';
import * as Styled from './modalTemplateStyes';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SelectiveLoggingServicePopUp from '../../SelectiveLoggingServicePopup/selectiveLoggingServicePopUp';
import { addTrailingSlash, isExternalUrlHref } from '../../../utils';

const ModalTemplate = (props) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderText>{children}</Styled.HeaderText>
      ),
    },
  };

  const [modalShowService, setModalShowService] = React.useState(false);
  const handleModalShowService = (isShow) => {
    isShow
      ? setModalShowService(isShow)
      : setModalShowService(!modalShowService);
  };

  return (
    props.imageSource &&
    (props?.anchorLink === '/modal/selectiveLoggingServicesPopup/' ? (
      <>
        <Styled.AnchorStyles
          onClick={() =>
            props?.anchorLink === '/modal/selectiveLoggingServicesPopup/'
              ? handleModalShowService()
              : {}
          }
        >
          <Col xl={12}>
            <Styled.ModalImage
              src={props?.imageSource}
              alt={props?.altText}
            ></Styled.ModalImage>
            {documentToReactComponents(
              JSON.parse(props?.paraText),
              optionsMainStyle
            )}
          </Col>
        </Styled.AnchorStyles>
        <SelectiveLoggingServicePopUp
          modalShow={modalShowService}
          handleModalShow={handleModalShowService}
        />
      </>
    ) : (
      <Styled.AnchorStyles
        href={
          isExternalUrlHref(props?.anchorLink)
            ? props?.anchorLink === '/modal/selectiveLoggingServicesPopup'
              ? undefined
              : props?.anchorLink
            : addTrailingSlash(
                process.env.GATSBY_DOMAIN_URL +
                  (props?.anchorLink === '/modal/selectiveLoggingServicesPopup'
                    ? undefined
                    : props?.anchorLink)
              )
        }
      >
        <Col xl={12}>
          <Styled.ModalImage
            src={props?.imageSource}
            alt={props?.altText}
          ></Styled.ModalImage>
          {documentToReactComponents(
            JSON.parse(props?.paraText),
            optionsMainStyle
          )}
        </Col>
      </Styled.AnchorStyles>
    ))
  );
};

export default ModalTemplate;
