import React from 'react';
import * as Styled from './selectiveLoggingServicePopUpStyles';
import constants from '../../utils/utils';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulImages } from '../../hooks/useContentfulImages';

const SelectiveLoggingServicePopUp = ({ modalShow, handleModalShow }) => {
  const SelectiveLoggingServicePopUpQuery = useStaticQuery(graphql`
    query SelectiveLoggingServicePopUpDefault {
      allContentfulColumbiaFuel(
        filter: {
          identifier: { type: { eq: "selectiveLoggingServicesPopup" } }
        }
      ) {
        edges {
          node {
            id
            slug
            title
            sections {
              ... on ContentfulContent {
                id
                contentDetails {
                  raw
                }
                type
              }
            }
          }
        }
      }
    }
  `);
  const SelectiveLoggingServicePopUpData =
    SelectiveLoggingServicePopUpQuery.allContentfulColumbiaFuel.edges[0].node
      .sections;

  const data = SelectiveLoggingServicePopUpData[0]?.contentDetails?.raw;

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.Header>{children}</Styled.Header>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        if (asset) {
          return (
            <Styled.TopImage
              src={asset?.node?.file?.url}
              alt={asset?.node?.title}
            />
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <>
      <Styled.MainModal
        show={modalShow}
        onHide={() => handleModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Styled.CloseImage
          onClick={() => {
            handleModalShow(false);
          }}
        />
        <Styled.CustomerSelect>
          <Styled.Column xs={12}>
            {documentToReactComponents(JSON.parse(data), optionsMainStyle)}
          </Styled.Column>
        </Styled.CustomerSelect>
      </Styled.MainModal>
    </>
  );
};
export default SelectiveLoggingServicePopUp;
